import React, { useEffect, useState } from 'react';
import bemCn from 'bem-cn';
import CookieService from '../../services/cookie.service';
import { SIGN_UP_SRC } from '../../consts/spot.consts';
import { RouteFormatter } from '../../routes';
import { COOKIE_PARAM_NAME } from '../../consts/cookies.consts';
import { ApolloClient, gql, InMemoryCache } from '@apollo/client';
import SniffButton from '../sniff-button';
import SvgCircleCheck from 'src/assets/svg/SvgCircleCheck.svg';
import './style.scss';

const b = bemCn('request-more-photos');

const RequestMorePhotos = ({ id }) => {
    const [client, setClient] = useState(null);
    const [req, setReq] = useState(false);

    const handleReq = async (e) => {
        try {
            e.preventDefault();
            e.stopPropagation();
            await client.query({
                query: gql`
                    query requestMorePhotos($id: ID!) {
                        requestMorePhotos(id: $id)
                    }
                `,
                variables: { id },
            });
            setReq(true);
        } catch (error) {
            if (error?.message == 'No user session') {
                const path = RouteFormatter.listings({ first: id });
                CookieService.set(COOKIE_PARAM_NAME.REDIRECT, path, { expires: 1 });
                CookieService.set(COOKIE_PARAM_NAME.SIGN_UP_FROM, SIGN_UP_SRC.PHOTO_REQUEST_WEB, { expires: 1 });
                window.location = RouteFormatter.signUp();
            }
        }
    };

    useEffect(() => {
        const cache = new InMemoryCache();
        setClient(new ApolloClient({ uri: '/graphql', cache }));
    }, []);

    return (
        <div className={b()}>
            <p className="snif-p snif-semibold text-center mb-3">
                Want to see more details?
                <br />
                Let the host know!
            </p>
            <div className="text-center">
                {req ? (
                    <p className={b('text-sent')}>
                        <SvgCircleCheck />
                        Request sent!
                    </p>
                ) : (
                    <SniffButton color="secondary" size="lg" onClick={handleReq}>
                        Request more photos
                    </SniffButton>
                )}
            </div>
        </div>
    );
};

export default RequestMorePhotos;
