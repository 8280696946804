import CookieService from '../services/cookie.service';

/**
 * Calculates the haversine distance between point A, and B.
 * @param {number[]} latlngA [lat, lng] point A
 * @param {number[]} latlngB [lat, lng] point B
 * @param {boolean} isMiles If we are using miles, else km.
 */
export const haversineDistance = ([lat1, lon1], [lat2, lon2], isMiles = true) => {
    const toRadian = (angle) => (Math.PI / 180) * angle;
    const distance = (a, b) => (Math.PI / 180) * (a - b);
    const RADIUS_OF_EARTH_IN_KM = 6371;

    const dLat = distance(lat2, lat1);
    const dLon = distance(lon2, lon1);

    lat1 = toRadian(lat1);
    lat2 = toRadian(lat2);

    // Haversine Formula
    const a = Math.pow(Math.sin(dLat / 2), 2) + Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.asin(Math.sqrt(a));

    let finalDistance = RADIUS_OF_EARTH_IN_KM * c;

    if (isMiles) {
        finalDistance /= 1.60934;
    }

    return finalDistance;
};

/**
 * Returns the distance to a spot from you.
 * @param {number} distance in float
 * @param {object} userDetails in redux.userDetails
 */
export const showDistanceFromYou = (distance, userDetails) => {
    const MAX_DISTANCE_IN_MI = 200;
    const MAX_DISTANCE_FOR_DECIMAL_IN_MI = 2;
    const myUser = typeof window === 'undefined' ? '{}' : CookieService.get('user');
    const parsedUser = JSON.parse(myUser);

    let tempDistance = distance;
    let text = '';

    if (
        (!userDetails?.id && !parsedUser?.userLocationConfirm) ||
        (userDetails?.id && !userDetails?.latitude && !userDetails?.longitude) ||
        typeof distance !== 'number' ||
        distance > MAX_DISTANCE_IN_MI
    ) {
        return '';
    }

    if (distance > MAX_DISTANCE_FOR_DECIMAL_IN_MI) {
        tempDistance = Math.round(distance);
    } else {
        tempDistance = parseFloat(distance.toFixed(1));
    }

    text = ` • ${tempDistance} mi from you`;

    return text;
};

export const resizeImage = (file, wantedWidth = 1896) =>
    new Promise((resolve) => {
        const dataUri = URL.createObjectURL(file);
        const imgEl = document.createElement('img');
        imgEl.onload = () => {
            URL.revokeObjectURL(dataUri);

            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const aspect = imgEl.width / imgEl.height;

            if (imgEl.width < wantedWidth && imgEl.height < wantedWidth) {
                resolve(file);
            }

            canvas.width = wantedWidth * aspect;
            canvas.height = wantedWidth;

            if (aspect < 1) {
                canvas.width = wantedWidth;
                canvas.height = wantedWidth / aspect;
            }

            ctx.drawImage(imgEl, 0, 0, canvas.width, canvas.height);
            canvas.toBlob((b) => {
                const resImg = new File([b], file.name, { type: file.type });
                resolve(resImg);
            }, file.type);
        };
        imgEl.src = dataUri;
    });
